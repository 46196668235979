<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.salary_sheet_staff') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="4" md="4" sm="12">
            <ValidationProvider name="Garden" vid="garden_id" rules="" v-slot="{ errors }">
            <b-form-group label-for="garden_id">
                <template v-slot:label>
                {{ $t('teaGardenConfig.select_garden') }}
                </template>
                <b-form-select
                    plain
                    v-model="search.garden_id"
                    :disabled="isGardenAdminCheckGardenId() ? true : false"
                    :options="teaGardenGenInfoList"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="year" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="year"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.year')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.year"
                      :options="yearList"
                      id="year"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Month" vid="month" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="month"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.month')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.month"
                      :options="monthList"
                      id="month"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.salary_sheet_staff') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <!-- <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a> -->
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="quick-filter-wrapper">
                  <div class="quick-filter-left">
                    <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                      <b-dropdown-form>
                        <div
                          class="form-group"
                          v-for="(field, index) in labelData"
                          :key="index"
                          >
                            <b-form-checkbox
                              :id="'checkbox-' + field.labels"
                              v-model="field.show"
                              :name="'checkbox-' + field.labels"
                              value=1
                              unchecked-value=0
                            >
                              {{ $t(field.labels)  }}
                            </b-form-checkbox>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(garden_id)="data">
                      {{ getGardenName(data.item.garden_id) }}
                  </template>
                  <template v-slot:cell(from_date)="data">
                      {{ data.item.from_date | dateFormat }}
                  </template>
                  <template v-slot:cell(to_date)="data">
                      {{ data.item.to_date | dateFormat }}
                  </template>
                  <template v-slot:cell(action)="data">
                      <router-link class="action-btn status" :title="$t('globalTrans.details')" :to="{name:'tea_garden_service.garden.staff_salary_view',
                        query :{
                          garden_id: parseInt(data.item.garden_id),
                          from_date: data.item.from_date,
                          to_date: data.item.to_date
                        }
                      }"><i class="ri-eye-line"></i></router-link>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './View.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { staffSalaryList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: {
    Form
  },
  data () {
    return {
      valid: null,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      search: {
        garden_id: 0,
        year: 0,
        month: 0,
        limit: 20
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'teaGardenConfig.tea_garden_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.from_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.to_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 6, thStyle: { width: '12%' } }
      ],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.search.year = new Date().getFullYear()
    if (this.isGardenAdminCheckGardenId()) {
          this.search.garden_id = this.isGardenAdminCheckGardenId()
    }
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    teaGardenGenInfoList: function () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    yearList () {
      return this.$store.state.TeaGardenService.commonObj.yearList.map(el => {
        return Object.assign({ value: el, text: this.$i18n.locale === 'en' ? el : this.$n(el, { useGrouping: false }) })
      })
    },
    monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
        })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'garden_id' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'garden_id' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('teaGarden.salary_sheet_staff') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGarden.salary_sheet_staff') + ' ' + this.$t('globalTrans.modify')
    }
  },
  methods: {
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
     getMonthName (id) {
        const data = this.$store.state.commonObj.monthList.find(item => item.value === id)
        if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        } else {
          return ''
        }
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        // this.changeStatus(3, teaGardenServiceBaseUrl, advanceSalaryToggle, item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, staffSalaryList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    }
  }
}
</script>
